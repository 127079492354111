
import { Component, Vue } from "vue-property-decorator";
import { HousfyInfoButtons } from "housfy-ui-lib";
import { resolveRoutePath } from "@/router";
import MYHExternalRoutes from "@/services/MYHExternalRoutes";

@Component({
  name: "NoPropertyAccess",
  components: { HousfyInfoButtons },
})
export default class NoPropertyAccess extends Vue {
  handleClickPrimary() {
    // eslint-disable-next-line xss/no-location-href-assign
    location.href = `${resolveRoutePath(
      MYHExternalRoutes.logout()
    )}?next=/login`;
  }
  handleClickSecondary() {
    // eslint-disable-next-line xss/no-location-href-assign
    location.href = resolveRoutePath(MYHExternalRoutes.myhHome());
  }
}
